.App {
  text-align: center;
}

/* .App .tbl-custom{
  --table-grid-template-columns: 0.5fr repeat(3, 1fr) !important;
  grid-template-columns: 0.5fr repeat(3, 1fr) !important;
} */

.App .tbl-custom [role=cell],
.App .tbl-custom [role=columnheader] {
  border-right: 1px solid var(--layout-border-color);
}

.App .tbl-cell-to-center,
.App .tbl-cell-to-center label,
.App .tbl-header-cell-to-center,
.App .tbl-header-cell-to-center * {
  display: inline-block;
  text-align: center;
  color: var(--color-black);
  font: var(--font-text2-normal)
}

#story-book-modal-title {
  display: none;
}

.App .main-form {
  padding-top: 1rem;
}

.loader-custom {
  width: 100px;
  height: 100px;
  top: 230px;
  right: 50%;
}

.App .loading-save {
  width: 8%;
  z-index: 100000;
  top: 50px;
  left: 45%;
  margin: unset;
}

/* .copy-container {
  position: absolute;
  font-size: 1.5rem;
  left: -65px;
} */

.custom-m-w {
  width: 200px;
  max-width: 200px;
}

.bg-dark-theme {
  background-color: #111425;
}

.monday-style-dialog-content-wrapper {
  z-index: 40;
}

.custom-overflow {
  overflow: hidden;
}

.custom-overflow:hover {
  overflow: auto;
}

.react-tooltip {
  z-index: 50;
}

.max-h-500 {
  max-height: 500px;
}